import { bookingsUouExplorePlansClick } from '@wix/bi-logger-wixboost-ugc/v2';
import { WixOOISDKAdapter } from '@wix/bookings-adapter-ooi-wix-sdk';
import type { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { NavigationAction } from '../handleNavigation/handleNavigation';
import { BiServiceType } from '../../utils/bi/consts';

export const createNavigateToPricingPlanAction =
  (
    wixSdkAdapter: WixOOISDKAdapter,
    flowApi: ControllerFlowAPI,
  ): NavigationAction =>
  (service, referral) => {
    const planIds = service.pricingPlanInfo?.pricingPlans.map(
      (plan) => plan.id,
    );

    const subtitle = flowApi.translations.t(
      'navigation.pricing-plans.subtitle',
      {
        serviceName: service.info.name,
      },
    );

    if (flowApi.environment.isPreview) {
      return wixSdkAdapter.navigateToPricingPlanPreview(
        { subtitle },
        flowApi.experiments,
      );
    }

    flowApi.bi?.report(
      bookingsUouExplorePlansClick({
        service_id: service.id,
        type: BiServiceType[service.type],
        isPendingApproval: service.schedulePolicy.isPendingApprovalFlow,
        referralInfo: referral,
      }),
    );

    return wixSdkAdapter.navigateToPricingPlan(
      {
        redirectTo: {
          sectionId: 'Booking Calendar',
          relativePath: service.urlName,
        },
        planIds: planIds!,
        subtitle,
        queryParams: {
          referral,
          service: service.id,
        },
      },
      flowApi.experiments,
    );
  };
